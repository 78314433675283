
import { useState, useEffect } from "react";
import { useAnimate, stagger, motion } from "framer-motion";


import "./index.css";
import "./background_anim.scss";


// Constante qui gère la durée entre les apparitions de chaque item du menu
// https://www.framer.com/motion/stagger/
// const staggerMenuItems = stagger(0.2 , { startDelay: 0, from: "center"});




function useMenuAnimation(isOpen: boolean) {
    const [scope, animate] = useAnimate();

    useEffect(() => {

        // agir sur la flèche du drop menu
        animate(".arrow", { rotate: isOpen ? 180 : 720 }, { duration: 1.5 });

        animate(
            "ul",
            {
                clipPath: isOpen
                    // commende la manière avec laquelle le background des items est refermé
                    // Départ:
                    ? "inset(0% 0% 0% 0% round 10px)"
                    // Arrivée:
                    : "inset(0% 0% 100% 0% round 10px)"
            },
            {
                // possibilités: "keyframes" | "decay" | "spring" | "tween" | "inertia" | undefined
                type: "spring",
                bounce: .2,
                // modifier la durée d'apparition du menu contenant les items
                duration: 0.3,

            }
        );

        animate(
            "li",
            isOpen
                // chnangement de la taille d'arrivée et de départ des items
                // Départ:
                ? { opacity: 1, scale: 1, filter: "blur(0px)",x:0  }
                // Arrivée:
                : { opacity: .4, scale: 3, filter: "blur(10px)",x:-500 },
            {
                // modifier la durée d'apparition des items
                duration: 0.3,
                delay:  isOpen ? stagger(0.3 , { startDelay: 0, from: "center"}):0,
                ease: "linear"
            }
        );
    }, [isOpen,animate]);

    return scope;
}

export default function App() {
    const [isOpen, setIsOpen] = useState(false);
    const scope = useMenuAnimation(isOpen);

    return (
        <div>
            <nav className="menu" ref={scope}>
                <motion.button
                    // taille du menu lorsqu'on clique dessus
                    whileTap={{ scale: 0.7 }}
                    //
                    onClick={() => setIsOpen(!isOpen)}
                    // style de l'affichage principal du menu
                    style={{
                        fontSize:"30px",}}
                >

                    <div
                        // div contenant le titre du menu
                        className="" style={{
                        fontWeight:"bold",
                        textAlign:"center",
                        position: "relative",
                        margin: "auto",
                        color: "black",
                    }} >
                        Navigation
                    </div>
                    <div className="arrow" style={{
                        // flèche à côté du titre du menu de navigation
                        fontWeight:"bold",
                        transformOrigin: "50% 55%" }} >
                        <svg width="25" height="25" viewBox="0 0 40 30">
                            <path d="M 0 7 L 40 7 L 20 30" />
                        </svg>
                    </div>
                </motion.button>
                <ul
                    style={{
                        pointerEvents: isOpen ? "auto" : "none",
                        clipPath: "inset(0% 0% 100% 0% round 10px)"
                    }}
                >
                    <li className="lien_nav" ><a href="https://youtube.com/shorts/8AmqCIjo8NM">Vid 1</a></li>
                    <li className="lien_nav"><a href="https://youtube.com/shorts/iDhpmZZX_gw">Vid 2 </a></li>
                    <li className="lien_nav"><a href="https://youtu.be/rLsimrBoYXc">Un long titre pour la Vid 3 </a></li>
                </ul>{" "}
            </nav>
            <div>

            </div>

        </div>


    );
}
