import { StrictMode, useState } from "react";
import * as ReactDOMClient from "react-dom/client";

import App from "./App";
import AppForName from "./AppForName";
import { Footer } from "./template/Footer";
import { Refresh } from "./template/Refresh";
import {LightGApp} from './LightG'


function AppWithUI() {
    const [count, setCount] = useState(0);

    return (
        <StrictMode>
            <Refresh onClick={() => setCount(count + 1)} />
            <App key={count} />

        </StrictMode>

    );
}

window.addEventListener("DOMContentLoaded", () => {
    const rootElement = document.getElementById("root");
    const my_galleryElement = document.getElementById("my_gallery");
    const name_render_divElement = document.getElementById("name_render_div_id");
    const footer_divElement = document.getElementById("footer_container_id");
    if (rootElement && my_galleryElement && name_render_divElement && footer_divElement){
        const root = ReactDOMClient.createRoot(rootElement);
        const my_gallery = ReactDOMClient.createRoot(my_galleryElement);
        const name_render_div = ReactDOMClient.createRoot(name_render_divElement);
        const footer_rnder = ReactDOMClient.createRoot(footer_divElement);
        root.render(<AppWithUI />);
        my_gallery.render(<LightGApp />);
        name_render_div.render(<AppForName />);
        footer_rnder.render(<Footer
            title="My Shiny Server"
            url="https://shiny.sekrane.fr"

        />);


    }

});


