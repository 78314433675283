
import { useState, useEffect } from "react";
import { useAnimate, motion } from "framer-motion";



// Constante qui gère la durée entre les apparitions de chaque item du menu
// https://www.framer.com/motion/stagger/



function useMenuForName(isOpen_2: boolean) {
    const [scope, animate] = useAnimate();

    useEffect(() => {

        // agir sur la flèche du drop menu

    }, [isOpen_2]);

    return scope;
}

export default function AppForName() {
    const [isOpen_2, setIsOpen_2] = useState(false);
    const namedisplay = useMenuForName(isOpen_2)

    return (
        <div>
            <nav className="menuForName" ref={namedisplay}>
                <motion.button
                    // taille du menu lorsqu'on clique dessus
                    whileTap={{ scale: 0.85 }}
                    //
                    onClick={() => setIsOpen_2(!namedisplay)}
                    // style de l'affichage principal du menu
                    style={{
                        fontSize:"30px",}}
                >
                    <a className={"div_social"} href="https://www.instagram.com/aidem.art/" target="_blank">
                    <div
                        // div contenant le titre du menu
                        className="div_social" >
                        Aidem ART &nbsp;&nbsp;&nbsp;
                        <img src="/supplement/insta.svg" alt="Mon Insta"></img>
                    </div></a>

                </motion.button>
                {" "}
            </nav>
            <div>

            </div>

        </div>


    );
}