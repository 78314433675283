import React, { useCallback, useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';



import LightGallery from 'lightgallery/react';
import { LightGallerySettings } from 'lightgallery/lg-settings';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';



export function LightGApp() {
    const videoMaxWidth = '55px';
    const youTubePlayerParams = {
        autoplay:0,
        modestbranding: 0,
        showinfo: 0,
        controls: 1,
        rel: 0,
        start:60,
    };
    const onAfterOpen = (detail :any) => {
    };
    const lightGallery = useRef<any>(null);
    const [items, setItems] = useState([

        /*{
            id: '2',
            // size: '1280-720',

            src: 'https://youtu.be/PnlND_z1vUs',
            thumb:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
            subHtml: `<div class="lightGallery-captions">
                <h4>Photo by <a href="######">Dan</a></h4>
                <p>Published on ######</p>
            </div>`,
        },*/


    ]);


    const onInit = useCallback((detail: any) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);



    useEffect(() => {
        lightGallery.current.refresh();
    }, [items]);

    return (
        <div className="LightGApp">
            <LightGallery
                videojs={true}
                onAfterOpen={onAfterOpen}
                plugins={[lgVideo, lgZoom]}
                elementClassNames="LightGApp"
                onInit={onInit}
                loadYouTubeThumbnail={true}
                youTubeThumbSize={1}
                youTubePlayerParams={youTubePlayerParams}
            >
                <a
                    className="lg_video"
                    href="https://www.youtube.com/watch?v=YbYWhdLO43Q"
                >

                    <img
                        className="lg_object lg_thumbnail"
                        src="https://img.youtube.com/vi/YbYWhdLO43Q/mqdefault.jpg"
                        alt="This Unicorn Changed the Way I Poop"/>
                </a>

                <a
                    className="lg_image"
                    href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg"
                >

                    <img
                        className="lg_object lg_thumbnail_img"
                        src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg"
                        alt="Magnificien sight"/>
                </a>



                <a
                    className="lg_video"
                    href="https://youtu.be/P_1N6_O254g"
                >
                    <img
                        className="lg_object lg_thumbnail"
                        src="https://img.youtube.com/vi/P_1N6_O254g/mqdefault.jpg"
                        alt="Dvořák: Symphony No. 9"/>
                </a>

                <a
                    className="lg_image"
                    href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg"
                >

                    <img
                        className="lg_object lg_thumbnail_img"
                        src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg"
                        alt="Magnificien sight"/>
                </a>

            </LightGallery>
        </div>
    );
}



// render(<LightGApp />, document.getElementById('root'));
